import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Mailmodel } from '../models/mailmodel';
import { MailerService } from '../Services/mailer.service'
  
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit{
  usermodel = new Mailmodel('', '', '', 0);
  submited = false;
  public Contact_one = "No matter wherein you're withinside the making plans process, our product professionals are satisfied to assist you. Our professional web development specialists will talk about your plans and challenges, examine your current web or cellular apps or maybe make a few preliminary recommendations. We will work with you to make certain your 100% satisfied."
  msg: string = "";
  constructor(private msgService: MailerService) { }
  
  ngOnInit(): void {
  }

  onSubmit(){
    if (this.usermodel == null){
      return;
    }
      this.submited = true;
      this.msgService.sendMessage(this.usermodel).subscribe((response: any) =>{
        console.log(response);
      });
      this.msg = "Email sent.";
  } 
}
