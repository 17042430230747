<!-- navigation -->
<div class="container">
	<div class="navbar navbar-default navbar-static-top" role="navigation">
		<div class="navbar-header">
			<button class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
				<div class="icon icon-bar"></div>
				<div class="icon icon-bar"></div>
				<div class="icon icon-bar"></div>
			</button>
			<a routerLink="home" class="navbar-brand"><img src="/assets/images/lo.png" class="img-responsive logo" alt="logo" style="height: 25; width: 25;"></a>
		</div>
		<div class="collapse navbar-collapse">
			<ul class="nav navbar-nav navbar-right">
				<li><a routerLink="/home">Home</a></li>
				<li><a routerLink="/services">Services</a></li>
				<li><a routerLink="/about">Our Team</a></li>
				<li><a routerLink="/contact">Get in touch</a></li>
			</ul>
		</div>
	</div>
</div>		
 
<!--Routing navigation-->
<router-outlet></router-outlet>