import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';

//adding routes
const routes: Routes = [
  {path: 'home', component: IndexComponent},
  {path: 'services', component: PortfolioComponent},
  {path: 'about', component: AboutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'sendmail', component: IndexComponent },
  {path: 'privacy', component: PrivacyComponent},
  {path: 'terms', component: TermsComponent},

   // redirect to `root page if url give is null or invalid`
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
