import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }

  demoapi(fdata: FormData) {
    return this.http.post('http://localhost/Anton-Coding-Solutions-PTY-LTD-Website/AntonCodingSolutions/src/assets/php/anton_newsletter_api.php', fdata);
  }
}
