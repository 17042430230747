<!-- about header section -->
<div id="about-header">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12"></div>
			<div class="home_animation">
				<h3>Our team</h3>
				<h1>Meet the team.</h1>
				<a routerLink="/contact" class="btn btn-default">Get in touch</a>
			</div>
		</div>
	</div>
</div>

<!-- team section -->
<div id="team">
	<div class="container">
    
		<div class="row">
			<div class="col-md-offset-2 col-md-8 col-sm-12">
				<h2>Development Team</h2>
				<p>{{about_the_company_paragraph1}}</p>
				<p>{{about_the_company_paragraph2}}</p>
			</div>
         </div>
         
         <div class="row mt30">
			<div class="col-md-4 col-sm-4 col-xs-9">
				<div class="team-wrapper">
					<img src="assets/images/director.jpeg" class="img-responsive" alt="team img" style="height: 350px; width: 330px;">
					<h2>Dumisani Mabuza</h2>
					<h4>CEO of Anton Coding Solutions</h4>
				</div>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-9">
				<div class="team-wrapper">
					<img src="assets/images/Profile_CasswelTest.jpeg" class="img-responsive" alt="team img" style="height: 350px; width: 330px;">
					<h2>Caswell Sibiya</h2>
					<h4>Partner</h4>
				</div>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-9">
				<div class="team-wrapper">
					<img src="assets/images/Profile_NathiTest.jpg" class="img-responsive" alt="team img" style="height: 350px; width: 330px;">
					<h2>Nkosinathi Mabuza</h2>
					<h4>Partner</h4>
				</div>
			</div>
         </div>
         
         <div class="row mt30"> 
			<div class="col-md-12 col-sm-12">
				<hr>
			</div>
			<div class="col-md-6 col-sm-6">
				<h3>Why us?</h3>
				<p>{{anton_studios}}</p>
				<p>{{anton_studios_two}}</p>
				<p>{{anton_studios_three}}</p>

			</div>
			<div class="col-md-6 col-sm-6">
				<strong>Transparent Service</strong>
				<div class="pull-right">100%</div>
					<div class="progress">
						<div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%;"></div>
					</div>
				<strong>Project Delivery</strong>
				<div class="pull-right">80%</div>
					<div class="progress">
						<div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%;"></div>
					</div>
				<strong>Dedicated Teams</strong>
				<div class="pull-right">100%</div>
					<div class="progress">
						<div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%;"></div>
					</div>
			</div>
		</div>
	</div>
</div>


<!-- divider section -->
<div class="divider">
	<div class="container">
		<div class="row">
			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-one">
					
					<img src="assets/images/big-data-element.svg" height="100" width="100" alt="">
					<h2>Consultative Service</h2>
					<p>
						We follow a consultative approach to understand your requirements first and then we put forward a suitable set of solutions accordingly.
					</div>
			</div>
			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-four">
					<img src="assets/images/web_mob_dev_bann.svg" height="100" width="100" alt="">		
					<h2>Quality Project Delivery</h2>
					<p>
						100% Quality requirements and Client pride are what we guarantee and make certain while we deliver. We try to enhance our people and strategies continuously.
					</p>
				</div>
			</div>
			<div class="col-md-4 col-sm-12">
				<div class="divider-wrapper divider-three">
					<img src="assets/images/developer-png-clipart.png" height="100" width="100" alt="">
					<h2>Dedicated Teams</h2>
					<p>
						Our app developers are equipped with excellent communication skills to ensure on-point communication process to understand and deliver exactly what you need.			
					</p>
				</div>
			</div>
		</div>

		<div class="space">

		</div>

	</div>
</div>
