<!-- privacy header section -->
<div id="privacy-header">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12"></div>
			<div class="home_animation">
				<h3>Privacy & Policy</h3>
				<h1>Documentation.</h1>
			</div>
		</div>
	</div>
</div>
