<!-- footer section -->
<footer>
	<div class="container">
		<div class="row">
        
			<div class="col-md-5 col-sm-4">
				<img src="/assets/images/lo.png" class="img-responsive" alt="logo">
				<p>At
                <a rel="nofollow" href="http://www.facebook.com/templatemo" target="_parent">Anton Coding Solutions.</a> 
				We let you think and we coding it.</p>
				<p><i class="fa fa-phone"></i> 081-780-7818</p>
				<p><i class="fa fa-envelope-o"></i> info@antoncoding.com</p>
              <p><i class="fa fa-globe"></i> www.antoncoding.com</p>
			</div>

			<div class="col-md-3 col-sm-4">
				<h3>Important</h3>
				<p><a routerLink="/home">Home</a></p>
				<p><a routerLink="/services">Services</a></p>
				<p><a routerLink="/about">Our team</a></p>
				<p><a routerLink="/contact">Get in touch</a></p>
				<p><a routerLink="/privacy">Privacy & Policy</a></p>
				<p><a routerLink="/terms">Terms of use</a></p>
			</div>
            
			<div class="col-md-4 col-sm-4 newsletter">
				<h3>Newsletter</h3>
				<p>Subscribe to our newsletter to hear about more ways Anton Coding Solutions have made life easy for our clients.</p>
				<div class="input-group">
           	    	<form action="footer.component.html" method="POST" #frm="ngForm" (ngSubmit)="saveapi(frm.value)">
                        <input name="email" type="text" placeholder="Enter your email" class="form-control" autorequired ngModel>
                    	<button type="submit" name="submit" class="btn email" ngModel>Submit</button>
                  	</form>
				 </div>
			</div>
            
		</div>
	</div>
</footer>


