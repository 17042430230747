import { Injectable } from '@angular/core';
import { WebServiceService } from './web-service.service';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  constructor(private webReqService: WebServiceService)  { }
   
  //Send contact object to server
  sendMessage(body: any) {
    return this.webReqService.post('sendmail', body);
  }
}
