<!-- contact header section -->
<div id="contact-header">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12"></div>
			<div class="home_animation">
				<h3>Get in touch</h3>
				<h6>Let us help you.</h6>
			</div>
		</div>
	</div>
</div>

<!-- contact section -->
<div id="contact">
	<div class="container">
		<div class="row">
			<div class="col-md-offset-2 col-md-8 col-sm-12">
				<h2>Get in touch</h2>
				<p>{{Contact_one}}</p>
                <p>We're ready to lead you into the future of topnoch websites, database, mobile and desktop software systems. We'd like to hear from you</p>
			</div>
			{{usermodel.name}}
			<div class="col-md-8 col-sm-8 mt30">
				<form method="POST" (ngSubmit)="onSubmit()" role="form">
					<div class="col-md-6 col-sm-6 FormGroup">
                        <label for="name">NAME</label>
                        <input name="name" type="text" class="form-control" id="name" [(ngModel)]="usermodel.name">
                      <label for="email">EMAIL</label>
                        <input name="email" type="email" class="form-control" id="email" [(ngModel)]="usermodel.email">
					</div>
					<div class="col-md-6 col-sm-6">
			 			<label for="cell">CELL</label>
                        <input name="cell" type="tel" class="form-control" id="password" [(ngModel)]="usermodel.cell">
					</div>
					<div class="col-md-6 col-sm-6">
						<label for="message">MESSAGE</label>
						<textarea name="message" rows="6" class="form-control" id="message" [(ngModel)]="usermodel.message"></textarea>
					</div>
                    <div class="col-md-6 col-sm-6">
						<button type="submit" class="btn btn-default">SEND</button>
                    </div>
					{{ msg }}
				</form>
			</div>

			<div class="col-md-4 col-sm-4 address">
				<div>
					<h3>Email</h3>
					<p>queries@antoncoding.com</p>
				</div>
				<div>
					<h3>Phones</h3>
					<p>081-780-7818</p>
				</div>
			</div>
			<!--<div class="col-md-12 col-sm-12 mt20">
            	<h3>Location</h3>
				<div class="google_map mt20">
					<div id="map-canvas"></div>
				</div>
			</div> -->     
		</div>
	</div>
</div>		

