<!-- portfolio header section -->
<div id="portfolio-header">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12"></div>
			<div class="home_animation">
				<h3>Our Services</h3>
				<h1>What we do.</h1>
				<a routerLink="/contact" class="btn btn-default">Get in touch</a>
			</div>
		</div>
	</div>
</div>


<!-- divider section -->
<div class="divider">
	<div class="container">
		<div class="row">
			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-one">
					<img src="assets/images/android_developer.svg.png" height="70" width="70" alt="">
					<h2>Android</h2>
				</div>
			</div>
			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-two">
					<img src="assets/images/React_icon.svg.png" height="70" width="70" alt="">
					<h2>React Native</h2>
				</div>
			</div>
			<div class="col-md-4 col-sm-12">
				<div class="divider-wrapper divider-three">
					<img src="assets/images/flutter.png" height="70" width="70" alt="">
					<h2>Flutter</h2>
				</div>
			</div>
		</div>
	</div>

	<div class="space">

	</div>
	<div class="container">
		<div class="row">

			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-one">
					<img src="assets/images/Angular_full.svg.png" height="70" width="70" alt="">
					<h2>Angular</h2>
				</div>
			</div>

		</div>
	</div>
</div>


<!-- portfolio section -->
<div id="portfolio">
	<div class="container">
		<div class="row">
			<div class="col-md-offset-2 col-md-8 col-sm-12">
				<h2>Our Projects</h2>
                <p>This area is reserved for you. Contact us now to get your solutions displayed and advertised here for the whole world to see.</p>
			</div>

			<!-- Each and every project made by anton coding solutions will be advertised here -->
			<!-- <div class="row mt30">
				<div class="col-md-4 col-sm-4 col-xs-6">
					<a href="" data-lightbox-gallery="portfolio-gallery">
						<img src="" alt="portfolio img"></a>
				</div>
         	</div> -->
		</div>
	</div>
</div>		