import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  name: String = "";
  email: String = "";
  response: String = "";

  constructor(private us: UserService) { }
  saveapi(val){
    console.log(val);
    this.email = val.email;

    var fd = new FormData();
    fd.append("email", val.email);
    this.us.demoapi(fd).subscribe((response) => {
      response = response;
    });
  }

  ngOnInit () : void {

  }
}
