<!-- home section -->
<div id="home">
	<div class="container">
		<div class="row">
			<div class="home_animation">
				<h3>{{welcome_name}}</h3>
				<h6>{{company_name}}</h6>
				<h5><span></span></h5>
			</div>
		</div>
	</div>
</div>

<!-- divider section -->
<div class="divider">
	<div class="container">
		<div class="row">
			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-one">
					<img src="assets/images/software-development-header.svg" height="100" width="100" alt="">
					<h2>Web Development</h2>
					<p>
						{{Web_Development}}
					</p>
				</div>
			</div>
			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-two">
					<img src="assets/images/web_developer.svg" height="100" width="100" alt="">
					<h2>User Interface/User Experience</h2>
					<p>
						{{ui_ux_Development}}
					</p>
				</div>
			</div>
			<div class="col-md-4 col-sm-12">
				<div class="divider-wrapper divider-three">
					
					<img src="assets/images/Custom-Software-icon-1.svg" height="100" width="100" alt="">

					<h2>Software Maintenance</h2>
					<p>
						{{Software_Maintenance}}
					</p>
				</div>
			</div>
		</div>

		<div class="space">

		</div>

		<div class="row" >
			<div class="col-md-4 col-sm-6">
				<div class="divider-wrapper divider-four">
					<img src="assets/images/ecommerce-development.svg" height="100" width="100" alt="">
		
					<h2>E-Commerce Software</h2>
					<p>
						{{ECommerce_Software}}
					</p>
				</div>
			</div>
			<div class="col-md-4 col-sm-12">

			</div>
		</div>
	</div>
</div>

<!-- about section -->
<div id="about">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-sm-12">
				<img src="assets/images/about-img.jpg" class="img-responsive" alt="about img">
			</div>
			<div class="col-md-6 col-sm-12 about-des">
				<h1>Tailored Software.</h1>
				<p>
					{{company_mission}} 
				</p>
				<p>
					{{company_vision}}
				</p>
				<a routerLink="/contact" class="btn btn-default">LEARN MORE</a>
			</div>
		</div>
	</div>
</div>

<!-- portfolio section -->
<div id="portfolio">
	<div class="container">
		<div class="row">
			<div class="col-md-offset-2 col-md-8 col-sm-12">
				<h2>Recent Projects</h2>
                <p>{{Recent_Projects}}</p>
			</div>
         </div>
         
         <div class="row mt30">
            
			<div class="col-md-4 col-sm-4 col-xs-6">
				<a href="assets/images/portfolio-img1.jpg" data-lightbox-gallery="portfolio-gallery"><img src="assets/images/portfolio-img1.jpg" alt="portfolio img"></a>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-6">
				<a href="assets/images/portfolio-img2.jpg" data-lightbox-gallery="portfolio-gallery"><img src="assets/images/portfolio-img2.jpg" alt="portfolio img"></a>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-6">
				<a href="assets/images/portfolio-img3.jpg" data-lightbox-gallery="portfolio-gallery"><img src="assets/images/portfolio-img3.jpg" alt="portfolio img"></a>
			</div>
            
			<div class="col-md-3 col-sm-6 col-xs-6">
				<a href="assets/images/portfolio-img4.jpg" data-lightbox-gallery="portfolio-gallery"><img src="assets/images/portfolio-img4.jpg" alt="portfolio img"></a>
			</div>
			<div class="col-md-3 col-sm-6 col-xs-6">
				<a href="assets/images/portfolio-img5.jpg" data-lightbox-gallery="portfolio-gallery"><img src="assets/images/portfolio-img5.jpg" alt="portfolio img"></a>
			</div>
			<div class="col-md-3 col-sm-6 col-xs-6">
				<a href="assets/images/portfolio-img6.jpg" data-lightbox-gallery="portfolio-gallery"><img src="assets/images/portfolio-img6.jpg" alt="portfolio img"></a>
			</div>
			<div class="col-md-3 col-sm-6 col-xs-12">
				<a href="assets/images/portfolio-img7.jpg" data-lightbox-gallery="portfolio-gallery"><img src="assets/images/portfolio-img7.jpg" alt="portfolio img"></a>
			</div>
			<div class="col-md-12 col-sm-12">
				<a routerLink="/services" rel="nofollow" class="btn btn-default">View More</a>
			</div>
		</div>
	</div>
</div>