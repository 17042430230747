import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public about_the_company_paragraph1 = "Anton Coding Solutions was founded by Mr. Dumisani Mabuza a young graduate from the University of Johannesburg who completed his studies in computer science and Informatics. His passion for software engineering led him to self-discover how his skills and expertise could be a solution in our modern world today, henceforth it became a burning urge that took him the entrepreneurial route."
  public anton_studios = "Anton coding Solutions styles build, implements, maintains, supports, associated custom package solutions on each web and mobile platforms."
  public anton_studios_two = "we are using an Agile software development approach, our groups are ready to support the event of tailored solutions across the complete software development lifecycle."
  public about_the_company_paragraph2 = "The dream of the company became alive when two amazing developers Mr. Caswell Sibiya and Mr. Nkosinathi Mabuza had the same mission as the founder. The team players ultimately became shareholders in the company."
  public anton_studios_three = "Hire Anton Coding Solutions Development team. We try for 100% patron pride together with integrity and transparency. With dash pace optimisation assured via planning, design, code, test, installation and run."

  constructor() { }

  ngOnInit(): void {
  }
}
