import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public welcome_name = "welcome to"
  public company_name = "Anton Coding Solutions"
  public company_mission = "The mission of Anton Coding Solutions is to help start-up businesses or well-established companies became more lucrative in their respective fields of doing business by utilizing the web. The software solutions and marketing services offered by the company are the dynamics any business needs to be kept up to date with the evolving corporate world and their relevancy in the economy."
  public company_vision = "The vision of the company is to have potential clients that will grow with us Anton Coding Solutions and have long-term relationships that will be beneficial to both parties."
  public Recent_Projects = ""
  public ECommerce_Software = "Entry-level online stores? we have the technical ability to deliver on your requirements and provide well-performing online store."
  public Software_Maintenance = "Ongoing maintenance and support helps ensure your organisation can minimise downtime and enjoy a stable digital environment."
  public Web_Development = "We offer total control over the line to give you the ability to implement your participation in general, we will provide Internet features."
  public ui_ux_Development = "Create a responsive application user interface (UI) with thoughtful and highly engaging user experience (UX)."


  constructor() { }

  ngOnInit(): void {
  }

}
